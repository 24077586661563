<template>
    <div class="flex flex-col w-full">
        <div class="flex flex-col">
            <div class="flex justify-between">
                <label :class="['block text-sm font-medium mb-1', businessUnitError ? 'text-red-700' : 'text-gray-700']">Select business units</label>
                <!--                <div v-if="!loading && businessUnits.length > 1" class="flex space-x-2">-->
                <!--                    <div v-if="checkedBusinessUnits.length > 0" class="hover:text-blue-700 cursor-pointer" @click="deselectAll">Deselect all</div>-->
                <!--                    <div v-if="checkedBusinessUnits.length !== businessUnits.length" class="hover:text-blue-700 cursor-pointer" @click="selectAll">Select all</div>-->
                <!--                </div>-->
            </div>

            <div :class="['border rounded p-6', businessUnitError ? 'border-red-700' : 'border-gray-200']">
                <div v-if="loading" class="flex items-center">
                    <span class="fas fa-spinner-third fa-spin mr-2"></span>
                    <translate>Loading</translate>
                    ...
                </div>

                <div v-if="!loading" class="flex flex-wrap">
                    <div v-if="businessUnits.length === 0" class="text-orange-700"> There are no business units to show.</div>

                    <div v-else class="flex flex-wrap w-full items-center space-y-2">
                        <div v-for="(businessUnit, index) in businessUnits"
                             :key="businessUnit.id"
                             :class="['flex items-center w-1/2 cursor-pointer text-gray-700 hover:text-blue-700', index%2 === 0 ? 'pr-2' : 'pl-2']"
                             @click="onChange(businessUnit.id)">
                            <input type="checkbox"
                                   class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                   :checked="checkedBusinessUnits.includes(businessUnit.id)">
                            <div class="sm:text-sm ml-2 truncate">{{ businessUnit.name }}</div>
                        </div>

                    </div>
                </div>
            </div>
            <div v-if="businessUnitError" class="mt-1 text-red-700 text-xs"><i class="fal fa-exclamation-triangle mr-2"/>{{ businessUnitError }}</div>
        </div>

        <div class="border-t border-gray-100 pt-6 mt-6">
            <label class="block text-sm font-medium mb-1 text-gray-700">Select brands</label>
            <div class="border rounded p-6 border-gray-200">
                <div v-if="checkedBusinessUnits.length === 0" class="text-xs text-gray-600">Please select the business unit first.</div>
                <div v-else>
                    <div v-for="(businessUnitId, index) in checkedBusinessUnits"
                         :key="businessUnitId"
                         :class="['flex flex-col items-start cursor-pointer', index === checkedBusinessUnits.length - 1 ? '' : 'mb-4']">

                        <div :class="['mb-3 pb-1 border-b w-full font-semibold', brandError(businessUnitId) ? 'text-red-700 border-red-200' : 'text-gray-700 border-gray-200' ]">
                            {{ getBusinessUnitName(businessUnitId) }}
                        </div>
                        <div class="flex flex-wrap w-full">
                            <div v-for="(brand, index) in companyBrands"
                                 :key="brand.id"
                                 :class="['flex w-1/2 hover:text-blue-700', index%2 === 0 ? 'pr-2' : 'pl-2', brandError(businessUnitId) ? 'text-red-700' : 'text-gray-700']"
                                 @click="handleBrandClick(brand.id, businessUnitId)">
                                <input type="checkbox"
                                       class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                       :checked="brandSelected(brand.id, businessUnitId)">
                                <div class="sm:text-sm ml-2 truncate">{{ brand.name }}</div>
                            </div>
                        </div>

                        <div v-if="brandError(businessUnitId)" class="mt-3 pt-1 border-t border-red-200 text-red-700 text-xs w-full">
                            <i class="fal fa-exclamation-triangle mr-2"/>{{ brandError(businessUnitId) }}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import axios from "axios";
import {filter, find, head} from "lodash";

export default {
    name: "BusinessUnitsSelector",
    props: {
        selectedBusinessUnits: {
            type: Array,
            default: () => []
        },
        selectedBrands: {
            type: Array,
            default: () => []
        },
        errors: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            fetchError: null,
            formErrors: {},
            loading: false,
            businessUnits: [],
            companyBrands: [],
            checkedBusinessUnits: [],
            checkedCompanyBrands: []
        }
    },

    watch: {
        errors() {
            this.formErrors = this.$props.errors;
        }
    },

    computed: {
        businessUnitError() {
            let error = null;
            if (this.fetchError) {
                error = this.fetchError
            } else {
                if (this.formErrors['businessUnits']) {
                    error = this.formErrors['businessUnits']
                }
            }
            return error;
        }
    },
    methods: {
        brandError(businessUnitId) {
            let error = null;
            if (this.formErrors['brands'] && this.formErrors['brands'][businessUnitId]) {
                error = this.formErrors['brands'][businessUnitId]
            }

            return error
        },
        async fetchBusinessUnits() {
            try {
                this.loading = true;
                this.fetchError = null;
                const response = await axios.get('/manager/expert3/xp3/company-business-units-and-brands/', {withCredentials: true})
                if (response.data.result === 'Ok') {
                    this.businessUnits = response.data.businessUnits;
                    this.companyBrands = response.data.companyBrands;
                    // this.$store.commit('master/setCountries', mappedCountries);
                } else {
                    this.fetchError = 'Loading business units failed. Please try again.'
                }
                this.loading = false;
            } catch (err) {
                this.fetchError = 'Loading business units failed. Please try again.'
                this.loading = false;
            }
        },

        brandSelected(brandId, businessUnitId) {
            const record = head(filter(this.checkedCompanyBrands, cb => cb.businessUnitId === businessUnitId));
            if (record) {
                return record.brands.includes(brandId)
            }

            return false
        },

        handleBrandClick(brandId, businessUnitId) {
            const record = find(this.checkedCompanyBrands, cb => cb.businessUnitId === businessUnitId);
            if (record) {
                const currentBrands = record.brands;
                const updatedBrands = currentBrands.includes(brandId) ? [...filter(currentBrands, br => br !== brandId)] : [...currentBrands, brandId]
                const updatedBrand = {businessUnitId: businessUnitId, brands: updatedBrands}
                this.checkedCompanyBrands = [...filter(this.checkedCompanyBrands, cb => cb.businessUnitId !== businessUnitId), updatedBrand]
            } else {
                this.checkedCompanyBrands = [...this.checkedCompanyBrands, {businessUnitId: businessUnitId, brands: [brandId]}]
            }

            this.$emit('on-brand-change', {brandId, businessUnitId})
        },

        getBusinessUnitName(businessUnitId) {
            const businessUnit = head(filter(this.businessUnits, bu => bu.id === businessUnitId))
            if (businessUnit) {
                return businessUnit.name
            }

            return ''
        },

        onChange(businessUnitId) {
            const found = find(this.checkedBusinessUnits, item => item === businessUnitId);
            this.checkedBusinessUnits = found ? filter(this.checkedBusinessUnits, item => item !== businessUnitId) : [...this.checkedBusinessUnits, businessUnitId];
            this.$emit('on-business-unit-change', businessUnitId)
        },

        // selectAll() {
        //     const allBusinessUnits = [];
        //     each(this.businessUnits, bu => allBusinessUnits.push(bu.id));
        //     this.checkedBusinessUnits = allBusinessUnits
        // },
        // deselectAll() {
        //     this.checkedBusinessUnits = []
        //     forEach(this.businessUnits, bu => {
        //         this.$emit('on-business-unit-change', bu.id)
        //     })
        // }
    },

    created() {
        this.checkedBusinessUnits = this.$props.selectedBusinessUnits;
        this.checkedCompanyBrands = this.$props.selectedBrands;
        this.fetchBusinessUnits();
    }
}
</script>

<style scoped>

</style>